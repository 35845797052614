<script setup lang="ts">
import { useModal } from 'vue-final-modal'
import { createColumnHelper } from '@tanstack/vue-table'
import type { TanstackTableColumn } from '@ui/components/TanstackTable'
import {
  type IManagerFormDataItem,
  type INodeObject,
  joinPath,
  parseSizeToGrid,
  type RepeaterItem,
  useObjectVModel,
  useRepeater,
} from '@manager'
import TableModal from './Shared/Modal.vue'
import type { IManagerTableEmits, IManagerTableProps } from './types'
import { getModalConfig } from '@manager/components/Group/Table/utils'

const props = withDefaults(defineProps<IManagerTableProps>(), {
  parentValue: () => ({}),
  parentPath: '',
})
const emit = defineEmits<IManagerTableEmits>()

const colSize = computed(() => parseSizeToGrid(props.node.size))

const objectNode = props.node.nodes[0] as INodeObject
const objectPath = computed(() => joinPath(props.parentPath, objectNode.name))
const joinDataPath = (index: number) =>
  joinPath(objectPath.value, `data[${index}]`)
const [parentValue, dataModelValue] = useObjectVModel(
  {
    ...props,
    node: objectNode,
  },
  'parentValue',
  emit,
)

const { items, filteredItems, add, updateById, removeById, getById } =
  useRepeater(dataModelValue)

const columnHelper = createColumnHelper<any>()
const columns = computed(() => {
  const columns = objectNode.nodes.map((node) =>
    columnHelper.accessor(node.name, {
      header: node.label ?? undefined,
    }),
  )

  return [
    ...columns,
    columnHelper.display({
      id: 'actions',
    }),
  ]
})

const itemValue = ref<RepeaterItem<IManagerFormDataItem>>()
const itemIndex = ref<number>()
const { open: openModal, close } = useModal({
  component: TableModal,
  attrs: {
    node: props.node,
    objectNode,
    // @ts-expect-error - Reactivity
    path: computed(() => joinDataPath(itemIndex.value!)),
    // @ts-expect-error - Reactivity
    value: itemValue,
    onConfirm: (item) => {
      try {
        // Updating
        if (itemValue.value) {
          updateById(itemValue.value.__id, item)
          itemValue.value = undefined
          // Adding
        } else {
          add(item)
        }
      } catch (e) {
        console.error(e)
      }
      close()
    },
    onClose: () => {
      itemValue.value = undefined
      return close()
    },
  },
})

const addButtonText = computed(() => {
  const { confirmButton } = getModalConfig(props.node.actions?.add, {
    confirmButton: 'Add',
  })

  return confirmButton
})
const onAdd = () => {
  itemIndex.value = items.value.length
  openModal()
}
const onEdit = (id: string) => {
  const item = getById(id)
  if (item) {
    itemValue.value = item
    itemIndex.value = items.value.indexOf(item)
    openModal()
  }
}

const confirm = useConfirm()
const onRemove = (id: string) => {
  const { title, body, confirmButton } = getModalConfig(
    props.node.actions?.delete,
    {
      title: 'Delete item',
      body: 'Are you sure you want to delete this item?',
      confirmButton: 'Delete',
    },
  )
  confirm.require({
    title,
    body,
    submitButton: confirmButton,
    confirm: () => removeById(id),
  })
}
</script>

<template>
  <div
    :class="[colSize, 'grid grid-flow-row grid-cols-1 gap-2']"
    :data-path="objectPath"
  >
    <div class="text-base text-white">{{ node.label }}</div>

    <!-- TODO: Tanstack table cache is not updating, so we need to display the items (hidden) to force the cache to update -->
    <div class="hidden">
      {{ filteredItems }}
    </div>

    <TanstackTable
      class="border-gray-750 border"
      :data="[...filteredItems]"
      :columns="columns"
      :sticky="{ scroller: null }"
      rounded
    >
      <template #empty>
        <div
          class="flex items-center justify-center px-4 py-3 text-sm text-gray-500"
        >
          <span>
            {{ node.emptyMessage ?? "You haven't added items yet." }}
          </span>
        </div>
      </template>
      <template #item-actions="{ item: { __id } }: TanstackTableColumn">
        <div class="flex justify-end gap-2">
          <Button
            v-if="node.actions?.edit"
            color="link"
            size="sm"
            icon="edit"
            class="text-primary hover:bg-primary-600 opacity-0 transition duration-100 hover:text-black group-hover/tr:opacity-100"
            @click.prevent="onEdit(__id)"
          />
          <Button
            v-if="node.actions?.delete"
            color="link"
            size="sm"
            icon="trash"
            class="text-red-500 opacity-0 transition duration-100 hover:bg-red-500 hover:text-red-100 group-hover/tr:opacity-100"
            @click.prevent="onRemove(__id)"
          />
        </div>
      </template>
      <template #footer>
        <div
          v-if="node.actions?.add"
          :class="{
            'mx-4 flex': filteredItems.length > 0,
          }"
        >
          <ButtonAddNewSection
            :title="addButtonText"
            icon="plus"
            size="xs"
            hide-line
            @click.prevent="onAdd()"
          />
        </div>
      </template>
    </TanstackTable>
  </div>
</template>
